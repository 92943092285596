<template lang="pug">
  .h-100.maxContainer.mx-auto
    //- .row.justify-content-center
    //-   .col-11.header
    //-     div(style="text-align: center")
    //-       img.client_logo( :src="Logo" v-openlog)
    .main_box
      Header.m-0
      .TitleSection.mt-4.pt-3.pb-3 Tu videollamada ha finalizado
      p.TextSection.mx-5.mt-5.pb-4 Gracias por utilizar nuestros servicios. Te esperamos en una proxima ocasión.
      p.FontDesc.text-start.textLargeDes.px-4.mt-5.pt-5 Recuerda que también puedes solicitar tus autorizaciones médicas mediante nuestros canales virtuales App y Web Colmédica de manera rápida y segura.
      footer.ButtonFixed.position-fixed
        b-button(v-if="naturaleza" variant="whitecolmedica" disabled).buttonBack Ya puedes cerrar esta ventana
        a(
          @click="goToSchedule"
        ).mt-2.d-block.text-center.LinkUnderlined: small.TextSection Ver videollamadas programadas
    div(class="div_center_button" style="bottom: 14vh;" v-if="!naturaleza")
      b-button(@click="finishTurnClose" variant="bluecolmedica") Volver a Profile_dev
</template>

<script>
import { mapActions, mapState } from "vuex";
import LS from "../helpers/localStorage";
import Header from "./autoservice/components/Header";
// import LogoColmedica from "@/assets/colmedica_logo.png";

export default {
  name: "Cancelled",

  data: () => ({
    naturaleza: LS.getItem("naturaleza")
    // LogoColmedica
  }),

  components: {
    Header
  },

  created() {
    this.startupLocal();
  },

  beforeDestroy() {
    this.clearLog();
  },

  computed: {
    ...mapState(["env"]),

    demoMode() {
      return this.env.VUE_APP_DEMO_MODE === "true";
    },

    LogoDemo() {
      return this.env.VUE_APP_DEMO_LOGO;
    },

    Logo() {
      return this.demoMode && this.LogoDemo
        ? this.LogoDemo
        : this.env.VUE_APP_LOGO || this.LogoColmedica;
    }
  },

  methods: {
    ...mapActions({
      cancelTurn: "cancelTurn",
      endMeet: "meeting/endMeet",
      putLog: "log/put",
      clearLog: "log/clear",
      goToView: "autoservice/goToView"
    }),

    async finishTurnClose() {
      LS.removeItem("naturaleza");
      this.$router.push({
        name: "Profile_dev"
      });
    },

    goToSchedule() {
      this.$router.push("/ad/");
      this.goToView("ToSchedule");
    },

    async startupLocal() {
      try {
        await this.cancelTurn();
        this.putLog({
          name: "Canceled. Turno cancelado."
        });
      } catch (error) {
        this.putLog({
          name: "Canceled. Error no restrictivo. No se pudo cancelar el turno.",
          checked: false,
          message: error
        });
        console.error("Error in cancelTurn in canceled page", error);
      }
      try {
        await this.endMeet();
        this.putLog({
          name: "Canceled. Videollamada cancelada."
        });
      } catch (error) {
        this.putLog({
          name:
            "Canceled. Error no restrictivo. No se pudo cancelar la videollamada.",
          checked: false,
          message: error
        });
        console.error("Error in endMeet in canceled page", error);
      }
    }
  }
};
</script>
<style scoped>
.main_text {
  font-size: 1.5em;
}
.main_box {
  width: 100%;
  margin: auto;
  text-align: center;
  font-size: 1em;
  color: black;
  background-color: white;
  height: fit-content;
  padding-bottom: 8rem;
}
.maxContainer {
  max-width: 411px;
  background-color: white;
}
.client_logo {
  width: 140px;
  height: auto;
}
.header {
  margin-top: 3vh;
  margin-bottom: 3vh;
}
.text-header-container {
  position: relative;
  height: 10vh;
  width: 50%;
  float: left;
}
.turn-detail {
  padding: 3vh;
  font-size: 1.5em;
  font-weight: 600;
}
.div_center_button {
  position: absolute;
  bottom: 3vh;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  left: 0;
  right: 0;
  width: 90%;
}
.ButtonFixed {
  bottom: 34px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 280px;
  width: 100%;
}

.LinkUnderlined {
  color: var(--color-gray);
  text-decoration: underline;
}

.textLargeDesc {
  color: #66707e;
}

.buttonBack {
  font-weight: 500;
  font-size: 18.3521px;
  line-height: 27px;
  position: fixed;
  bottom: 31.11px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 380px;
  width: 100%;
  opacity: 1;
  background-color: #f4f4f4;
}
</style>
